<template>
    <!-- eslint-disable -->
    <Form :action="action" :dataLayananVal="dataLayanan" :layananVal="layanan" :jenisPemeriksaanVal="jenisPemeriksaan" :detailSediaanVal="[{label: null}]" />
</template>

<script>
import Form from './Form.vue'
import {ref } from '@vue/composition-api'

export default {
    setup(props, context){
        const g = context.root
        const $http = g.$http

        var dataLayanan = ref([])

        const getData = () => {
            $http({
                url: '/pemeriksaan/jenisPemeriksaan/tambah/',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataLayananVal} = res.data.data.dataJenisPemeriksaan
                    dataLayanan.value = dataLayananVal
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Get Data`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()
        return {
            dataLayanan
        }
    },
    data(){
        return{
            action: 'Tambah',
            layanan: null,
            jenisPemeriksaan: null
        }
    },
    components: {
        Form
    }
}
</script>