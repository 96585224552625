<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>{{action}} Data</b-card-title>
                <b-card-title class="text-primary">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        class="btn-icon"
                        size="sm"
                        :to="{name: 'mstPemeriksaanSediaan'}"
                    >
                        <feather-icon icon="ArrowLeftIcon" />
                    </b-button>
                </b-card-title>
            </b-card-header>

            <b-card-body>
                <b-form class="needs-validation" @submit.prevent="submitForm">
                    <b-form-row>
                        <b-col
                            md="10"
                            offset-md="1"
                        >
                            <!-- input label -->
                            <b-form-group
                                label="Label"
                                label-for="label"
                            >
                                <b-form-input
                                    id="label"
                                    v-model="v$.label.$model"
                                    :state="validateState(v$.label)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.label.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input layanan -->
                            <b-form-group
                                label="Layanan"
                                label-for="layanan"
                            >
                                <b-form-select
                                    v-model="v$.layanan.$model"
                                    :options="dataLayanan"
                                    :state="validateState(v$.layanan)"
                                    @change="changeLayanan"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.layanan.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input jenisPemeriksaan -->
                            <b-form-group
                                label="Jenis Pemeriksaan"
                                label-for="jenisPemeriksaan"
                                v-if="isShowJenisPemeriksaan"
                            >
                                <b-form-select
                                    v-model="v$.jenisPemeriksaan.$model"
                                    :options="dataJenisPemeriksaan"
                                    :state="validateState(v$.jenisPemeriksaan)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.jenisPemeriksaan.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-row v-for="(item, index) of v$.detailSediaan.$model" :key="index">
                                <b-col
                                    cols="11"
                                >
                                    <!-- input detail sediaan -->
                                    <b-form-group
                                        label="Detail Sediaan"
                                        label-for="detailSediaan"
                                    >
                                        <b-form-input
                                            v-model="item.label"
                                            :state="validateState2(v$.detailSediaan.$each.$response.$data[index].label)"
                                        />
                                        <b-form-invalid-feedback v-for="(error, index2) of v$.detailSediaan.$each.$response.$errors[index].label" :key="index2">
                                            {{ error.$message }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="1"
                                    align-self="center"
                                    class="text-right"
                                >
                                    <b-button v-if="index == 0"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="btn-icon"
                                        size="sm"
                                        @click="detailSediaan.push({label: null})"
                                    >
                                        <feather-icon icon="PlusIcon" />
                                    </b-button>
                                    <b-button v-else
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="danger"
                                        class="btn-icon"
                                        size="sm"
                                        @click="detailSediaan.splice(index, 1)"
                                    >
                                        <feather-icon icon="MinusIcon" />
                                    </b-button>
                                </b-col>
                            </b-row>

                            <!-- tombol -->
                            <b-col
                                cols="12"
                                class="text-right pr-0"
                            >
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="button"
                                    variant="outline-secondary"
                                    class="mr-1"
                                    @click="resetForm"
                                >
                                    Reset
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                >
                                <div v-if="isSubmit">
                                    <b-spinner small />
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else>Simpan</div>
                                </b-button>
                            </b-col>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-card-body>
        </b-card>
    </div>
  
</template>

<script>
import {
    BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BRow
} from 'bootstrap-vue'
import { required, helpers} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed, watchEffect } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BRow
    },
    props: {
        action: String,
        labelVal: String,
        layananVal: String,
        dataLayananVal: Array,
        detailSediaanVal: Array,
        dataJenisPemeriksaanVal: Array,
        jenisPemeriksaanVal: String,
        isShowJenisPemeriksaanVal: Boolean,
        id: String,
    },
    setup(props, context){
        const g = context.root

        var isSubmit = ref(false)
        const router = g.$router
        const $http = g.$http

        //form name
        var label = ref('')
        var layanan = ref(null)
        var dataLayanan = ref([])
        var jenisPemeriksaan = ref(null)
        var detailSediaan = ref([
            {label: null}
        ])
        var dataJenisPemeriksaan = ref([])

        var isShowJenisPemeriksaan = ref(false)

        watchEffect(() => {
            label.value = props.labelVal
            layanan.value = props.layananVal
            detailSediaan.value = props.detailSediaanVal
            dataLayanan.value = props.dataLayananVal
            dataJenisPemeriksaan.value = props.dataJenisPemeriksaanVal
            jenisPemeriksaan.value = props.jenisPemeriksaanVal
            isShowJenisPemeriksaan.value = props.isShowJenisPemeriksaanVal
        })

        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            label: { 
                required: helpers.withMessage('Label '+requiredMsg, required)
            },
            jenisPemeriksaan: {},
            layanan: {},
            detailSediaan: {
                $each: helpers.forEach({
                    label: {
                        required: helpers.withMessage('Label '+requiredMsg, required)
                    }
                })
            }
        }))

        const v$ = useVuelidate(rules, { label, jenisPemeriksaan, layanan, detailSediaan }, {$externalResults, $autoDirty: true})

        const submitForm = async () => {
            isSubmit.value = true
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi
            if (!isFormCorrect) {
                isSubmit.value = false
                return
            }

            // mapping data
            let data = {
                label: label.value,
                jenisPemeriksaan: jenisPemeriksaan.value,
                layanan: layanan.value,
                detailSediaan: detailSediaan.value,
                id: props.id
            };

            $http({
                url: '/pemeriksaan/sediaan/submit/'+props.action,
                data,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    router.push({name: 'mstPemeriksaanSediaan'})
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changeLayanan = () => {
            if(!layanan.value)
                jenisPemeriksaan.value = null

            $http({
                url: '/pemeriksaan/jenisPemeriksaan/get-data-jenis-pemeriksaan',
                params: {
                    layanan: layanan.value,
                    jenisPemeriksaan: jenisPemeriksaan.value,
                    halaman: 'form'
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataJenisPemeriksaanVal, jenisPemeriksaanVal} = res.data.data
                    dataJenisPemeriksaan.value = dataJenisPemeriksaanVal
                    jenisPemeriksaan.value = jenisPemeriksaanVal

                    isShowJenisPemeriksaan.value = layanan.value ? true : false
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Table`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        const validateState2 = (item) => {
            const { $error } = item
            return $error ? false : null
        }

        const resetForm = () => {
            nama.value = ''
            emailName.value = ''
            peran.value = null
            password.value = ''
            konfirmasiPassword.value = ''
            username.value = ''
        }

        return {
            v$, $externalResults,
            label, jenisPemeriksaan, dataJenisPemeriksaan, layanan, dataLayanan, detailSediaan,
            isSubmit, isShowJenisPemeriksaan,
            resetForm, validateState, submitForm, changeLayanan, validateState2
        }
    },
    directives: {
        Ripple,
    },
}
</script>

<style lang="scss" scope>
</style>